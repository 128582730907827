.flavourCircle {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.flavourCircle span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 10px;
    font-size: 1.5rem;
}

.flvStrawberry {
    background-color: #69151d;
    color: #f60421;
}

.flvLemon {
    background-color: #737314;
    color: #f8f803;
}

.flvOrange {
    background-color: #73360b;
    color: #f76904;
}

.flvPeach {
    background-color: #994740;
    color: #f0aba5;
}

.flvVanilla {
    background-color: #a19467;
    color: #e6d6a2;
}