.review-section {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 50px;
}

.review-title {
    font-size: 3rem;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
    margin-bottom: 50px;
}

.review-box {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    position: relative;
    transition: transform 0.5s ease;
}

.cust-box-rating {
    width: 50px;
    height: 50px;
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateX(0);
    display: flex;
    align-items: center;
    box-shadow: 0 0 70px rgba(133, 133, 133, 0.7);
    border-radius: 100%;
    background-color: #fff;
    padding-left: 15px;
    position: absolute;
    right: -20px;
    top: -24px;
}

.cust-box-rating p {
    font-weight: bold;
}

.cust-box-rating span {
    position: absolute;
    top: 5px;
    color: gold;
}

.cust-box-middle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    gap: 30px;
    box-shadow: 0 0 70px rgba(133, 133, 133, 0.3);
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateX(0);
    position: relative;
}

.review-doddle {
    width: 70px;
    height: 70px;
    background-color: #fff;
    box-shadow: 5px 5px 40px rgba(56, 56, 56, 0.3);
    padding: 10px;
    border-radius: 50px;
    position: absolute;
    top: -40px;
    left: -30px;
}

.review-doddle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
    border-radius: 50px;
}

.review-name {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #009351;
}

.cust-msg {
    color: #888;
    text-align: center;
    font-size: 15px;
    line-height: 2;
}

.cust-msg span {
    color: #000;
    font-size: 13px;
    margin: 0 5px 0 5px;
}

.cust-box-right {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.fade-out-right {
    opacity: 0;
    transform: translateX(-20px);
}

.fade-out-left {
    opacity: 0;
    transform: translateX(20px);
}

.cust-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    padding: 15px;
    border: 0;
    outline: none;
    color: #fff;
    background-color: #007B44;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
    transition: .3s;
    border-radius: 100%;
}

.cust-btn:hover {
    background-color: #009351;
}

@media screen and (max-width: 640px) {

    .review-section {
        padding: 10px;
    }

    .review-box {
        width: 100%;
    }

    .review-doddle {
        width: 60px;
        height: 60px;
        top: -20px;
        left: -20px;
    }

    .cust-msg {
        font-size: 12px;
    }

    .cust-box-middle {
        padding: 50px 30px;
    }

    .review-title {
        text-align: center;
        font-size: 2rem;
    }
}