.location-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: -100px;
    padding: 130px;
}

.location-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.location-header-image {
    width: 100px;
    transform: rotate(-20deg);
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
}

.location-title {
    font-size: 6rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}

.location-map {
    width: 100%;
    height: 500px;
    max-width: 1100px;
    margin: 100px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 100px rgba(118, 118, 118, 0.7);
}

.location-map iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 640px) {
    .location-title {
        font-size: 2rem;
    }

    .location-header-image {
        width: 70px;
    }

    .location-map {
        margin: 20px;
        width: 300px;
    }
}