.menu-card {
    border-radius: 10px;
    margin: 20px;
    width: calc(100% - 40px);
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 280px;
    padding: 20px;
    background-color: #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: 0.3s ease;
}

.menu-card-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: 0.3s ease;
    border-radius: 10px;
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.6));
}

.menu-card:hover .menu-card-image {
    transform: translateY(-10px);
}

.menu-card-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    padding: 25px 0;
    background-color: #fff;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 10px;
    margin-top: -20px;
    position: relative;
}

.menu-card-doodle img {
    height: 50px;
    background-color: #fff;
    padding: 5px;
    width: 50px;
    object-fit: contain;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -40px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}

.menu-card-title {
    width: 100%;
    color: #007B44;
    font-size: 0.9rem;
}

@media screen and (max-width: 640px) {
    .menu-card {
        width: calc(100% - 40px);
    }
}