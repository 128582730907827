.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin-top: -100px;
    padding-top: 130px;
}

.about-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.about-header-image {
    width: 100px;
    transform: rotate(-20deg);
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
}

.about-title {
    font-size: 6rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}

.about-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 100px;
    width: 90%;
    max-width: 1100px;
}

.about-left-container {
    width: 100%;
    max-width: 50%;
    padding: 20px;
}

.about-left-container img {
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(10px 10px 10px rgba(61, 61, 61, 0.9));
}

.about-right-container {
    width: 100%;
    max-width: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.about-right-container p {
    line-height: 2;
    color: #999;
    font-weight: 500;
}

.about-right-container p b {
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}

@media screen and (max-width: 1024px) {
    .about-box  {
        flex-direction: column;
        width: 100%;
    }

    .about-right-container {
        max-width: 80%;
    }
}

@media screen and (max-width: 640px) {
    .about-container {
        flex-direction: column;
        text-align: center;
        width: 100%;
    }

    .about-title {
        font-size: 2rem;
    }

    .about-header-image {
        width: 70px;
    }

    .about-box {
        width: 90%;
        margin: 50px;
        text-align: start;
        flex-direction: column;
    }

    .about-left-container {
        max-width: 80%;
    }

    .about-right-container {
        width: 100%;
        padding: 20px;
    }

    .about-left-container img {
        width: 100%;
    }


    .about-right-container h2 {
        font-size: 24px;
    }

    .about-right-container p {
        font-size: 13px;
    }
}

@media screen and (max-width: 900px) {
    .about-box {
        margin: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        width: 100%;
    }

    .about-right-container {
        width: 100%;
        padding: 20px;
    }

    .about-left-container {
        max-width: 80%;
    }

    .about-left-container img {
        width: 100%;
    }

    .about-right-container {
        max-width: 100%;
    }
}