.hero-container {
    width: 100%;
    height: 120vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 130px;
}

.logo-container {
    width: 80%;
    height: 30%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.logo-container img {
    width: 80%;
    height: 100%;
    object-fit: contain;
}

.hero-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 70%;
}

.hero-left {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.hero-img-background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.hero-img-background img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    filter: drop-shadow(10px 10px 10px rgba(61, 61, 61, 0.9));
}

.hero-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
}

.hero-title {
    font-size: 5rem;
    font-weight: bolder;
    text-transform: uppercase;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}

.hero-sub-title {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 600;
    color: #555;
}

.hero-desc {
    color: #777;
    font-weight: 500;
    line-height: 2;
}

.hero-right-btn a {
    outline: 0;
    border: 0;
    padding: 15px 30px;
    background-color: #007B44;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: .3s;
}

.hero-right-btn a:hover {
    gap: 20px;
    background-color: #009351;
}

.mouse-scroll {
    transform: translateX(-50%);
    transform: rotate(-90deg);
}

.mouse-scroll span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    margin: 3px;
}

.mouse-scroll .down-arrow-1 {
    margin-top: 6px;
}

.mouse-scroll .down-arrow-1,
.mouse-scroll .down-arrow-2,
.mouse-scroll .down-arrow-3 {
    animation: mouse-scroll 1s infinite;
}

.mouse-scroll .down-arrow-1 {
    animation-delay: .1s;
    -moz-animation-delay: .1s;
    animation-direction: alternate;
}

.mouse-scroll .down-arrow-2 {
    animation-delay: .2s;
    -moz-animation-delay: .2s;
    animation-direction: alternate;
}

.mouse-scroll .down-arrow-3 {
    animation-delay: .3s;
    -moz-animation-dekay: .3s;
    animation-direction: alternate;
}

@keyframes animated-mouse {
    0% {
        opacity: 1;
        transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(6px);
        -ms-transform: translateY(6px);
        transform: translateY(6px);
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Media queries for additional device widths */
@media screen and (max-width: 1230px) {

    .logo-container {
        width: 100%;
    }

    .hero-box {
        width: 100%;
        gap: 10px;
        padding: 0;
        margin: 50px;
    }

    .hero-left {
        width: 50%;
    }

    .hero-right {
        width: 70%;
    }

    .hero-illustration-img {
        width: 65%;
    }

    .hero-title {
        font-size: 3rem;
    }

    .hero-sub-title {
        font-size: 0.9rem;
    }

    .hero-desc {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 1024px) {

    .logo-container {
        width: 100%;
        margin-top: 50px;
    }

    .hero-box {
        width: 100%;
        flex-direction: column;
        text-align: center;
        gap: 10px;
        padding: 0;
        margin: 50px;
    }

    .hero-left {
        width: 70%;
    }

    .hero-left img {
        height: 90%;
        width: 100%;
    }

    .hero-right {
        width: 70%;
    }

    .hero-desc {
        font-size: 0.8rem;
    }

    .hero-title {
        font-size: 3.5rem;
    }

    .hero-sub-title {
        font-size: 1.2rem;
    }

    .hero-desc {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1024px) and (max-height: 600px) {

    .logo-container {
        width: 100%;
        margin-top: 100px;
    }

    .hero-right {
        text-align: start;
    }

    .hero-title {
        font-size: 3.5rem;
    }

    .hero-sub-title {
        font-size: 1.2rem;
    }

    .hero-desc {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 1024px) and (max-height: 1366px) {

    .logo-container {
        width: 100%;
        margin-top: 50px;
    }

    .hero-box {
        height: 100vh;
        flex-direction: row;
    }

    .hero-left,
    .hero-right {
        width: 50%;
    }
}

@media screen and (max-width: 1280px) and (max-height: 800px) {

    .logo-container {
        width: 100%;
        /* margin-top: 100px; */
    }

    .hero-left img {
        object-fit: contain;
    }

    .hero-title {
        font-size: 4.2rem;
    }

    .hero-sub-title {
        font-size: 1.5rem;
    }

    .hero-desc {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 999px) {

    .logo-container {
        width: 100%;
        margin-top: 200px;
    }

    .hero-box {
        width: 100%;
        flex-direction: column;
        text-align: center;
        gap: 10px;
        padding: 0;
        margin: 50px;
    }

    .hero-left {
        width: 70%;
    }

    .hero-left img {
        width: 100%;
    }

    .hero-right {
        width: 70%;
    }
}

@media screen and (max-width: 745px) {

    .logo-container {
        width: 100%;
        margin-top: 150px;
    }

    .hero-box {
        flex-direction: column;
        text-align: center;
        gap: 10px;
        padding: 0;
    }

    .hero-left,
    .hero-right {
        width: 100%;
    }

    .hero-title {
        font-size: 2.5rem;
    }

    .hero-sub-title {
        font-size: 1rem;
    }
}

@media screen and (max-width: 440px) {

    .logo-container {
        width: 100%;
        margin-top: 50px;
    }

    .hero-box {
        width: 100%;
    }

    .hero-left {
        padding: 20px;
        width: 100%;
    }

    .hero-right {
        width: 100%;
    }

    .hero-title {
        font-size: 2.5rem;
    }

    .hero-sub-title {
        font-size: 1rem;
    }

    .hero-desc {
        font-size: 0.7rem;
    }

    .hero-right-btn a {
        font-size: 15px;
    }
}

@media screen and (max-width: 385px) {

    .logo-container {
        width: 100%;
        margin-top: 130px;
    }

    .hero-box {
        width: 100%;
    }

    .hero-right {
        text-align: center;
    }

    .hero-left img {
        width: 100%;
    }

    .hero-title {
        font-size: 1.8rem;
    }

    .hero-sub-title {
        font-size: 0.9rem;
    }

    .hero-desc {
        font-size: 0.6rem;
    }
}