.slider-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-box {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #fff;
    box-shadow: 0 0 70px rgba(133, 133, 133, 0.3);
    padding: 20px;
    border-radius: 180px 20px 5px 20px;
    position: relative;
    transition: transform 0.5s ease;
}

.doddle {
    position: absolute;
    width: 100px;
    height: 100px;
    object-fit: contain;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
}

.doddle-1 {
    top: -50px;
    transform: rotate(25deg);
}

.doddle-2 {
    right: 300px;
    bottom: 50px;
}

.doddle-3 {
    top: -50px;
    right: 100px;
    width: 80px;
    transform: rotate(-10deg);
}

.slider-box-left {
    width: 25%;
    height: 400px;
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateX(0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-box-left img {
    width: 100%;
    height: 80%;
    object-fit: contain;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
}

.slider-box-middle {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 30px;
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateX(0);
}

.fade-out {
    opacity: 0;
    transform: translateX(20px);
}

.slider-title {
    font-size: 2.5rem;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
    font-weight: bold;
}

.slider-description {
    color: #777;
}

.product-flv {
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1;
}

.slider-box-right {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.slider-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    padding: 15px;
    border: 0;
    outline: none;
    color: #fff;
    background-color: #007B44;
    font-weight: bold;
    cursor: pointer;
    z-index: 1;
    transition: .3s;
    border-radius: 100%;
}

.slider-btn:hover {
    background-color: #009351;
}


@media (max-width: 640px) {
    .slider-box {
        flex-direction: column;
        height: auto;
        gap: 30px;
    }

    .slider-box-left {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slider-box-left img {
        width: 70%;
    }

    .slider-box-middle {
        text-align: center;
        width: 100%;
    }

    .slider-title {
        height: 50px;
        font-size: 1rem;
    }

    .slider-description {
        font-size: 0.8rem;
    }

    .product-flv {
        justify-content: center;
    }

    .slider-box-right {
        width: 100%;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }

    .slider-btn {
        font-size: 1rem;
        padding: 15px;
    }

    .coffee-beans {
        width: 150px;
        height: 150px;
        right: 0px;
        bottom: -10px;
        transform: rotate(40deg);
        opacity: 0.2;
        overflow: hidden;
    }

    .doddle-1 {
        left: -40px;
        top: 200px;
        transform: rotate(10deg);
    }

    .doddle-2 {
        left: -50px;
    }

    .doddle-3 {
        right: -20px;
    }
}

@media screen and (max-width: 768px) {
    .slider-container {
        height: auto;
        margin: 100px 0;
    }

    .slider-box {
        flex-direction: column;
        height: auto;
        gap: 30px;
    }

    .coffee-beans {
        width: 350px;
        height: 350px;
        right: 0px;
        bottom: -25px;
        opacity: 0.2;
    }

    .slider-box-left {
        height: 300px;
    }

    .slider-box-left img {
        width: 100%;
    }

    .slider-box-middle {
        text-align: center;
        width: 80%;
    }

    .slider-title {
        height: 50px;
        font-size: 1.2rem;
    }

    .slider-description {
        font-size: 0.8rem;
        font-weight: 500;
    }


    .product-flv {
        justify-content: center;
    }

    .slider-box-right {
        width: 100%;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
    }

    .slider-btn {
        font-size: 1rem;
        padding: 15px;
    }

    .slider-btn {
        font-size: 1rem;
        padding: 15px;
    }

    .doddle-1 {
        left: -40px;
        top: 200px;
        transform: rotate(10deg);
    }

    .doddle-2 {
        left: -50px;
    }

    .doddle-3 {
        right: -20px;
    }
}

@media screen and (min-width: 769px) and (max-width:999px) {
    .slider-container {
        height: 100vh;
        margin: 100px;
    }
}

@media screen and (max-width: 1024px) {

    .slider-container {
        height: auto;
        /* margin: 100px 0; */
    }

    .slider-box {
        height: auto;
        gap: 30px;
    }

    .coffee-beans {
        width: 350px;
        height: 350px;
        right: 0px;
        bottom: -25px;
        opacity: 0.2;
    }

    .slider-box-middle {
        width: 80%;
    }

    .slider-title {
        font-size: 1.2rem;
    }

    .slider-description {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .doddle-2 {
        right: 300px;
        bottom: -10px;
    }
}