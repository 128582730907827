.navbar-container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    margin-top: 20px;
    box-shadow: 0 0 70px rgba(99, 99, 99, 0.6);
    backdrop-filter: blur(20px);
    position: sticky;
    top: 0px;
    border-radius: 50px;
    z-index: 999;
}

.logo {
    width: 210px;
    height: 55px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(5px 5px 10px rgba(61, 61, 61, 0.7));
}

.navlinks {
    display: flex;
    align-items: center;
    gap: 30px;
}

.navlinks a {
    color: #007B44;
    font-weight: 600;
    transition: 0.5s ease-in-out;
}

.navlinks a:hover {
    color: #009351;
}

.hamburger-menu {
    display: none;
}

@media screen and (max-width: 768px) {
    .navbar-container {
        width: 90%;
        border-radius: 5px;
        background-color: #fff;
    }

    .hamburger-menu {
        display: flex;
        position: absolute;
        right: 30px;
        font-size: 25px;
        cursor: pointer;
        color: #007B44;
    }

    .navlinks {
        width: 100%;
        position: absolute;
        top: 80px;
        right: 0;
        background-color: #fff;
        box-shadow: 0 30px 30px rgba(99, 99, 99, 0.6);
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        border-radius: 0 0 5px 5px;
        gap: 20px;
        padding: 10px;
        transition: all 0.5s;
        height: 0;
        opacity: 0;
    }

    .active-navbar {
        height: 400px;
        opacity: 1;
    }

    .navlinks a {
        width: 100%;
        padding: 15px 0;
        text-align: center;
        transition: .3s;
    }

    .navlinks a:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .no-radius {
        border-radius: 5px 5px 0 0;
    }

    .logo {
        width: 150px;
        height: 50px;
    }
}