.home-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    margin-top: -100px;
    gap: 100px;
}

@media (max-width: 640px) {
    .home-container {
        gap: 50px;
        height: auto;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .home-container {
        gap: 100px;
    }
}