.footer {
    width: 100%;
    padding-top: 50px;
    box-shadow: 0 0 100px rgba(96, 96, 96, 0.9);
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.footer-section {
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    gap: 20px;
}

.footer-section h2 {
    color: #007B44;
}

.footer-section p {
    color: #555;
    font-weight: 500;
}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo img {
    width: 200px;
    height: auto;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.social-icons a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 20px;
    text-decoration: none;
    transition: 0.3s;
}

.social-icons a:hover {
    color: #007B44;
}

.footer-bottom {
    background-color: #007B44;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    margin: 50px 0;
}

@media screen and (max-width: 640px) {
    .footer-section p {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .footer-section {
        text-align: center;
    }

    .footer-logo img {
        width: 200px;
    }
}

@media screen and (max-width: 875px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .footer-section {
        text-align: center;
    }
}