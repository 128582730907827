.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin-top: -100px;
    padding-top: 130px;
}

.contact-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.contact-header-image {
    width: 100px;
    transform: rotate(-20deg);
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
}

.contact-title {
    font-size: 5rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}

.contact-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px;
    width: 90%;
    max-width: 1100px;
}

.contact-left {
    width: 100%;
    padding: 10px;
}

.contact-left img {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-right {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
    margin: 50px 0;
}

.contact-number,
.contact-email {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    padding: 50px;
    font-size: 1.2rem;
    border-radius: 20px;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    box-shadow: 10px 10px 40px rgba(118, 118, 118, 0.8);
}


.contact-number:hover,
.contact-email:hover {
    background: linear-gradient(40deg, #8FCB6B, #007B44);
}

@media screen and (max-width: 1024px) {
    .contact-box {
        flex-wrap: wrap;
        width: 70%;
        margin: 20px;
    }

    .contact-number,
    .contact-email {
        font-size: 1.2rem;
        padding: 20;
    }
}

@media screen and (max-width: 768px) {

    .contact-title {
        font-size: 3rem;
    }

    .contact-box {
        flex-wrap: wrap;
        width: 100%;
        margin: 20px;
    }

    .contact-right {
        padding: 10px;
    }
}

@media screen and (max-width: 640px) {
    .contact-title {
        font-size: 2rem;
    }

    .contact-header-image {
        width: 70px;
    }

    .contact-box {
        flex-wrap: wrap;
        width: 100%;
        margin: 20px;
    }

    .contact-left {
        max-width: 100%;
    }

    .contact-left img {
        width: 100%;
    }

    .contact-right {
        padding: 10px;
    }

    .contact-number,
    .contact-email {
        text-align: center;
        font-size: 0.9rem;
    }
}