.drink-card {
    border-radius: 10px;
    box-shadow: 0 0 40px rgba(72, 72, 72, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}

.drink-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100px;
    padding: 10px;
}

.drink-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.3s ease;
    filter: drop-shadow(0 0 50px rgba(0, 0, 0, 0.5));
}

.drink-card:hover img {
    transform: scale(1.06);
}

@media screen and (max-width: 1024px) {
    .drink-image {
        height: 75px;
    }
}

@media screen and (max-width: 640px) {
    .drink-card {
        justify-content: center;
    }

    .drink-image {
        height: 75px;
    }
}