.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: 50px 0 50px 0;
    margin-top: -100px;
    padding-top: 130px;
}

.menu-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.menu-header-image {
    width: 100px;
    transform: rotate(-20deg);
    filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
}

.menu-title {
    font-size: 6rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
}

.menu-item {
    margin: 100px 150px 100px 150px;
}

.menu-item img {
    width: 100%;
    box-shadow: 0 0 50px rgba(27, 27, 27, 0.9);
}

.popular-drinks-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.popular-drinks-title {
    font-size: 3.5rem;
    letter-spacing: 2px;
    background: linear-gradient(40deg, #007B44, #8FCB6B);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
    margin-bottom: 50px;
}

.popular-drinks-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    margin: 20px;
    width: 80%;
}

@media screen and (max-width: 1024px) {

    .menu-title {
        font-size: 3.5rem;
    }

    .menu-header {
        margin-bottom: 20px;
    }

    .menu-item {
        margin: 10px 10px 50px 10px;
    }

    .popular-drinks-cards {
        gap: 30px;
    }
}

@media screen and (max-width: 640px) {
    .menu-container {
        flex-direction: column;
        text-align: center;
        width: 100%;
    }

    .menu-title {
        font-size: 1.8rem;
    }

    .menu-header-image {
        width: 60px;
    }

    .menu-item-title {
        font-size: 2rem;
    }

    .menu-item-description {
        font-size: 0.7rem;
    }

    .menu-item {
        margin: 10px 10px 50px 10px;
    }

    .popular-drinks-cards {
        gap: 30px;
    }

    .popular-drinks-title {
        text-align: center;
        font-size: 2rem;
    }
}